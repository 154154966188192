<template>
  <div>
    <ComingSoon />
  </div>
</template>

<script>
import ComingSoon from './components/business-rules/ComingSoon'

export default {
  name: 'AdminSecurity',
  components: {
    ComingSoon,
  },
}
</script>
